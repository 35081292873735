import React from 'react';

import { Section } from 'src/components/molecules/Section';
import { Accordion } from 'src/components/molecules/Accordion';

export default function LegacySection({ heading, callouts, customData }) {
  customData = JSON.parse(customData || '{}');
  const faqLists = callouts.filter((callout) => callout.__typename == 'DatoCmsFaqList');
  return (
    <Section {...customData.flags}>
      <h2 className="highlight">{heading}</h2>
      {faqLists.map((faqList, i) => {
        return (
          <article key={i} style={{ marginTop: 60 }}>
            <h3 style={{ textAlign: 'center' }}>{faqList.heading}</h3>
            <div style={{ textAlign: 'left' }}>
              <div dangerouslySetInnerHTML={{ __html: faqList.contentNode.childMarkdownRemark.html }} />
              <Accordion
                sections={faqList.faqs.map((faq) => ({
                  title: faq.question,
                  content: (
                    <div dangerouslySetInnerHTML={{ __html: faq.answerNode.childMarkdownRemark.html }} />
                  ),
                }))}
              />
            </div>
          </article>
        );
      })}
    </Section>
  );
}
